.about-page-row {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 1em;
  text-align: center;
}

.column-2-items,
.column-3-items {
  width: 100%;
  padding: 0.5em 0;
}

.about-page-title {
  width: 100%;
  text-align: center;
  font-size: 3.5em;
  color: var(--light-color);
}

.about-page-card {
  box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
  padding: 3.5em 1em;
  border-radius: 0.6em;
  color: #1f003b;
  cursor: pointer;
  transition: 0.3s;
  background-color: #ffffff;
}

.about-page-card .img-container {
  width: 8em;
  height: 8em;
  background-color: #a993ff;
  padding: 0.5em;
  border-radius: 50%;
  margin: 0 auto 2em auto;
}

.about-page-card img {
  width: 100%;
  border-radius: 50%;
}

.about-page-card h3 {
  font-weight: 500;
}

.about-page-card p {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0.5em 0 2em 0;
  letter-spacing: 2px;
}

.about-page-icons {
  width: 50%;
  min-width: 180px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.about-page-card a {
  text-decoration: none;
  color: inherit;
  font-size: 1.4em;
}

.about-page-card:hover {
  background: linear-gradient(#6045ea, #8567f7);
  color: #ffffff;
}

.about-page-card:hover .img-container {
  transform: scale(1.15);
}

.about-page-container {
  margin-top: 74px;
}

@media screen and (min-width: 992px) {
  .about-page-card {
    padding: 5em 1em;
  }

  .column-3-items {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 1em;
  }

  .column-2-items {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 1em;
    margin-bottom: 10px
  }
}