.memes-container {
  margin-top: 90px;
}

.memes-container .meme-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

@media screen and (max-width: 1024px) {
  .memes-container .meme-image {
    width: 80%;
  }
}
